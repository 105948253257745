import React, { useEffect, useState, useRef } from 'react'
import './navbar.scss'

import { Icon } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import logos from "./logos.png";
import { Link} from "react-router-dom";
import io from 'socket.io-client';
import useAuth from "../../hooks/useAuth";
import FourStarRating from '../starRating/StarRating'
import { getCategory, getLimiteSimultaneo, getFluxo, getChartData } from '../../api/client'
import { useQuery, useQueryClient, useMutation } from 'react-query'

const socket = io('https://www.taonclient.com/', {
  transports: ['polling'],
  upgrade: false 
});


const NavBar = (prop) => {

  const enableSound = JSON.parse(localStorage.getItem("soundPreference")) || false;

  const usuarioCash = JSON.parse(localStorage.getItem("usuarioCash"));
  const idEmpresa = (usuarioCash && Array.isArray(usuarioCash) && usuarioCash.length > 0) 
  ? usuarioCash[0]["id"] 
  : null;
  
  const { signout } = useAuth();
  const navigate = useNavigate();
  
  const [limSimultaneo, setLimSimultaneo] = useState('bloqueio');
  const [isShaking, setIsShaking] = useState(false);
  const [isSoundEnabled, setIsSoundEnabled] = useState(enableSound);
  const soundEnabledRef = useRef(isSoundEnabled);

  const [category, setCategory] = useState('Unknown Category');
  const star = {
    "Unknown": 0,
    "Comum": 1,
    "Premium": 2,
    "Premium Plus": 3,
    "Premium Plus Plus": 4,
  }
  
  const [flow, setFlow] = useState('');
  const flowData = [
    {name: "normal", label: 'Normal', color: "success"},
    {name: "atencao", label: "Atenção", color: "warning"},
    {name: "critico", label: "Crítico", color: "error"},
    {name: "desconhecido", label: "Desconhecido", color: 'primary'}
  ]

  useEffect(() => {
    if(idEmpresa){
      socket.emit('register', idEmpresa); 
  
      socket.on('limiteSimultaneoChange', (newValue) => {
        handleLimiteSimultaneoChange(newValue);  
      });

      socket.on('flowChange', (newValue) => {
        handleFlowChange(newValue);  
      });

      socket.on('categoryChange', (newValue) => {
        handleCategoryChange();  
      });
    
    }

  }, [idEmpresa]);

  useEffect(() => {
    if (isShaking) {
      const timer = setTimeout(() => {
        setIsShaking(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isShaking]);

  useEffect(()=>{
    const fetchCategory = async () => {
      if (idEmpresa) {
        try {
          const response = await getCategory(idEmpresa); 
          setCategory(response);
        } catch (error) {
          setCategory('Unknown');
          console.error('Error fetching category:', error);
        }

        try {
          const response = await getLimiteSimultaneo(idEmpresa); 
          setLimSimultaneo(response);
        } catch (error) {
          console.error('Error fetching limiteSimultaneo:', error);
        }

        try {
          const response = await getFluxo(idEmpresa); 
          setFlow(response);
        } catch (error) {
          setFlow('desconhecido');
          console.error('Error fetching flow:', error);
        }
      }
    };
    fetchCategory();
  }, [idEmpresa])

  useEffect(() => {
    const handleBodyClick = () => {
      console.log('clicked');
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };

  }, []);

  useEffect(() => {
    soundEnabledRef.current = isSoundEnabled;
    localStorage.setItem('soundPreference', JSON.stringify(isSoundEnabled));
  }, [isSoundEnabled]);
  
  function onSoundClick(){
    if (!isSoundEnabled){
      const audio = new Audio('./notification.wav');
      audio.play();
    }
    setIsSoundEnabled(!isSoundEnabled);
  }

  function handleLimiteSimultaneoChange(newValue){
    if(soundEnabledRef.current){
      if (newValue==='bloqueio'){
        const audio = new Audio('./ATIVAR.mp3');
        audio.play().catch((error) => {
          console.error('Audio playback failed:', error);
        });
      }
      else{
        const audio = new Audio('./DESATIVAR.mp3');
        audio.play().catch((error) => {
          console.error('Audio playback failed:', error);
        });
      }
    }

    setLimSimultaneo(newValue);
    setIsShaking(true);
  }

  function handleFlowChange(newValue){
    if(soundEnabledRef.current){
      const audio = new Audio('./ATIVAR.mp3');
      audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
      });
    }
    setFlow(newValue);
    setIsShaking(true);
  }

  async function handleCategoryChange(){
    if(soundEnabledRef.current){
      const audio = new Audio('./ATIVAR.mp3');
      audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
      });
    }
    const response = await getCategory(idEmpresa); 
    setCategory(response);
  }

  return (
    <div className="navbar" style={{width: prop.width}}>
      <div className="wrapper">
        <div className="logo">
          <Link to="/home" style={{ textDecoration: "none" }}>
            <img src={logos} alt="carregando" width={140} height={70} background={"white"}/>
          </Link>
        </div>
        { prop.usuarioAtivo === true &&

          <div className='row'>
            <FourStarRating rating={star[category]}/>

            {flowData.filter(item => item.name === flow)[0] &&
              <Chip 
                label={"Fluxo: " + flowData.filter(item => item.name === flow)[0]?.label}
                variant='outlined'
                className={isShaking ? 'shake' : ''}
                color={flowData.filter(item => item.name === flow)[0]?.color}
                style={{alignContent: 'center', margin: 'auto 5px', height: 'auto', padding: '4px'}}
                sx={{background: 'rgb(255 255 246 / 50%)'}}
              />
            }
            
            <Chip 
              label={
                <span>
                  Lim. simult. <strong>{limSimultaneo==='bloqueio' ? 'ON' : 'OFF'}</strong>
                </span>
              }
              className={isShaking ? 'shake' : ''}
              color={limSimultaneo==='bloqueio'?"success":"error"}
              variant='outlined'
              style={{alignContent: 'center', margin: 'auto 25px auto 0px', height: 'auto', padding: '4px'}}
              sx= {{ background: 'rgb(255 255 246 / 50%)'}}
            />
            
            <button
                onClick={onSoundClick}
                style={{background: 'rgba(255, 255, 246, 0.5)', border: 'none', margin: 'auto 10px', height: '10px', borderRadius: '50%', height:'auto', width: 'auto', color: '#6B4380', cursor: 'pointer' }}>
              {isSoundEnabled ? <VolumeUpOutlinedIcon /> : <VolumeOffOutlinedIcon />}
            </button>

            <div className='sair' onClick={() => [signout(), navigate("/login")]}>
              <Icon  className="icon-update">logout_icon</Icon>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default NavBar
